'use strict';

module.exports = {
    init: function () {
        console.log(document.documentElement.lang);
        let content = {
            "message": 'Oldalunk cookie-kat (sütiket) használ. Ezen fájlok információkat szolgáltatnak számunkra a felhasználó oldallátogatási szokásairól, de nem tárolnak személyes információkat. Weboldalunk böngészésével beleegyezel a cookie-k használatába.',
            "dismiss": 'Rendben',
            "link": 'Bővebben',
            "href": 'https://london2000.hu/adatvedelem/'
        };

        if (document.documentElement.lang === 'en-GB') {
            content = {
                "message": 'Our website uses cookies. These files provide us with information about a user’s page visit habits, but do not store any personal information. By browsing our website, you agree to the use of cookies.',
                "dismiss": 'Okay',
                "link": 'More',
                "href": 'https://london2000.hu/en/privacy-policy/'
            };
        }

        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#273754"
                },
                "button": {
                    "background": "#EB0C3D"
                }
            },
            "position": "bottom-left",
            "content": content
        });
    }
};