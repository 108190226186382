'use strict';

module.exports = {
    init: function () {
        this.$bar     = $('.js-zm-bars');
        this.$menu    = $('.js-zm-nav');
        this.$submenu = $('.menu-item-has-children');
        this.isShow   = false;

        this.initEventListeners();
    },

    initEventListeners: function () {
        this.submenu();

        this.$bar.on('click', () => {
            if (this.isShow) {
                this.isShow = false;

                return this.hide();
            }

            this.isShow = true;
            this.show();
        });
    },

    show: function () {
        this.$menu.css('display', 'block');
    },

    hide: function () {
        this.$menu.css('display', 'none');
    },

    submenu: function () {
        this.$submenu.on('click', function() {
            $(this).find('.sub-menu').slideToggle(200);
        })
    }
};